import React from "react";
import { Layout, SectionWrapper } from "../components/";

const NotFoundPage = () => (
  <Layout>
    <SectionWrapper title="OOPSY DAISY" className="cross">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </SectionWrapper>
  </Layout>
);

export default NotFoundPage;
